module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Harriet & Esteban Vicente Foundation","short_name":"Vicente","start_url":"/","background_color":"#ffffff","theme_color":"#a77a74","display":"standalone","icon":"./src/images/icons/ms-icon-70x70.png","icons":[{"src":"./src/images/icons/ms-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/icons/ms-icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"./src/images/icons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"./src/images/icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./src/images/icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/images/icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./src/images/icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cebfdf17df30b628a1011fbb4480835b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-X16FNDDJ14"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
