import React from 'react'
import { ThemeProvider } from './src/context/ThemeContext'
//import { Profiler } from 'react'

export const onRouteUpdate = ({ location, prevLocation }) => {
   console.log('new pathname', location.pathname)
   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

export const wrapRootElement = ({ element, props }) => {
   return (
      //<Profiler id="General">
      <ThemeProvider>{element}</ThemeProvider>
      //</Profiler>
   )
}
