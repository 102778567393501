import React from 'react'

const defaultState = {
   searchIsOpen: false,
   open: false,
   toggleOpen: () => {},
   isStudio: false,
   setIsStudio: () => {},
   goBack: false,
   setGoBack: () => {},
   // infinite scroll for artworks
   cursor: 1 /* Which page infinite scroll should fetch next. */,
   data: {},
   data2: [],
   useInfiniteScroll: true /* Toggle between pagination and inf. scroll for this demo & fallback in case of error. */,
   isInitializing: () => true,
   updateState: () => {},
   hasMore: () => {},
   loadMore: () => {},
   toggle: () => {},
   toogleSearcIsOpen: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
   state = {
      searchIsOpen: false,
      open: false,
      goBack: false,
      isStudio: false,
      cursor: 1,
      useInfiniteScroll: true,
      isInitializing: null,
      updateState: null,
      hasMore: null,
      data: {},
      data2: [],
      loadMore: null,
      toggle: null,
      error: null,
   }

   toggleOpen = () => {
      const isOpen = !this.state.open
      localStorage.setItem('open', JSON.stringify(isOpen))
      this.setState({ open: isOpen })
   }

   toogleSearcIsOpen = () => {
      this.setState({ searchIsOpen: !this.state.searchIsOpen })
   }

   setGoBack = val => {
      console.log('---- goBack ', val)
      this.setState({ goBack: val })
   }

   setIsStudio = val => {
      this.setState({ isStudio: val })
   }

   isInitializing = () => {
      return this.state.cursor === 1
   }
   updateState = mergeableStateObject => {
      this.setState(mergeableStateObject)
   }

   loadMore = () => {
      const location = window.location.host
      const protocol = window.location.protocol
      console.log(
         'Fetching metadata for page ' + this.state.cursor,
         `${protocol}//${location}`
      )
      const pageNum = this.state.cursor
      this.setState(state => ({ cursor: state.cursor + 1 })) // TODO: make sure this is guaranteed to set state before another loadMore may be able to fire!

      fetch(
         `${protocol}//${location}/paginationJson/esteban-vicente/artworks/${pageNum}.json`
      )
         .then(res => res.json())
         .then(
            res => {
               //this.state.data[('page', pageNum)] = res
               this.setState({
                  data: { ...this.state.data, ['page' + pageNum]: res },
                  data2: [...this.state.data2, ...res],
                  //data: [...this.state.data, res],
               })

               //this.setState({
               //['page' + pageNum]: res,
               //})
               console.log(
                  'Result from context: ',
                  'page' + pageNum,
                  this.state
               )
            },
            error => {
               this.setState({
                  error,
                  useInfiniteScroll: false, // Fallback to Pagination on error.
               })
            }
         )
   }

   hasMore = pageContext => {
      if (!this.state.useInfiniteScroll) return false
      if (this.isInitializing()) return true
      return this.state.cursor <= pageContext.numPages
   }
   /** This exists to demo toggling. You will not need this in production. */
   toggle(useInfiniteScroll, pageContext) {
      if (useInfiniteScroll) {
         /* Toggle back to infinite scroll, adjust scroll position. Otherwise we might load 1000s of items at once. */
         const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
         window.scrollTo(0, scrollTop - 1)
         this.setState({
            useInfiniteScroll: true,
         })
      } else {
         /* Toggle back to pagination, reset items and cursor. */
         const state = {}
         for (var i = this.state.cursor - 1; i >= 0; i--) {
            state['page' + i] = undefined
         }
         //state['page' + pageContext.currentPage] = pageContext.pageImages
         //console.log('Result toogle')
         state.data['page' + pageContext.currentPage] = pageContext.pageImages
         state['cursor'] = pageContext.currentPage + 1
         state['useInfiniteScroll'] = false
         this.setState(state)
      }
   }

   componentDidMount() {
      //construct
      console.log('*** Constructing Global State ***')

      //Getting open mode value from localStorage!
      const lsopen = JSON.parse(localStorage.getItem('open'))

      if (lsopen) {
         this.setState({ open: lsopen })
      }

      this.setState({
         isInitializing: this.isInitializing,
         loadMore: this.loadMore,
      })
   }

   render() {
      const { children } = this.props
      const {
         searchIsOpen,
         open,
         goBack,
         isStudio,
         useInfiniteScroll,
         data,
         data2,
      } = this.state
      console.log(('themeProvider', goBack))
      return (
         <ThemeContext.Provider
            value={{
               searchIsOpen,
               toogleSearcIsOpen: this.toogleSearcIsOpen,
               open,
               toggleOpen: this.toggleOpen,
               goBack,
               setGoBack: this.setGoBack,
               isStudio,
               setIsStudio: this.setIsStudio,
               // global state data gallery
               useInfiniteScroll,
               /* Toggle between pagination and inf. scroll for this demo & fallback in case of error. */
               data,
               data2,
               updateState: this.updateState,
               hasMore: this.hasMore,
               toggle: this.toggle,
               isInitializing: this.isInitializing,
               loadMore: this.loadMore,
            }}
         >
            {children}
         </ThemeContext.Provider>
      )
   }
}

export default ThemeContext

export { ThemeProvider }
