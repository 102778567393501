// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-esteban-vicente-content-index-jsx": () => import("./../../../src/pages/esteban-vicente/content/index.jsx" /* webpackChunkName: "component---src-pages-esteban-vicente-content-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-studio-index-jsx": () => import("./../../../src/pages/studio/index.jsx" /* webpackChunkName: "component---src-pages-studio-index-jsx" */),
  "component---src-templates-artwork-index-jsx": () => import("./../../../src/templates/artwork/index.jsx" /* webpackChunkName: "component---src-templates-artwork-index-jsx" */),
  "component---src-templates-artworks-index-jsx": () => import("./../../../src/templates/artworks/index.jsx" /* webpackChunkName: "component---src-templates-artworks-index-jsx" */),
  "component---src-templates-gallery-index-jsx": () => import("./../../../src/templates/gallery/index.jsx" /* webpackChunkName: "component---src-templates-gallery-index-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/page/index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-workshop-index-jsx": () => import("./../../../src/templates/workshop/index.jsx" /* webpackChunkName: "component---src-templates-workshop-index-jsx" */)
}

